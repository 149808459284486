module.exports = [{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-remark-images@6.25.0_17081080b80e587d79047f4efb88516f/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"maxWidth":650,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-remark-images-medium-zoom@1.7.0/node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-remark-autolink-headers@1.4.19/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"30","icon":false,"className":"custom-class","maintainCase":true},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_gatsby@4.25.4/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Optipass Document","short_name":"Optipass","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"static/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4cc0486f14a89f2bb77102a26621eb4f"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby@4.25.4_f44b2b0fb7f373a65fd05a813d9f5fce/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
